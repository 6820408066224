@mixin generate_size($width, $height) {
  min-width: $width;
  min-height: $height;
  max-width: $width;
  max-height: $height;
}

.two-user-avatar {
  border-radius: 50%;
  background: #ccc;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  margin-right: -5px;
  margin-left: -5px;

  &.small {
    @include generate_size(24px, 24px);
  }

  &.normal {
    @include generate_size(30px, 30px);
  }

  &.medium {
    @include generate_size(40px, 40px);
  }

  &.large {
    @include generate_size(80px, 80px);
  }

  &.xLarge {
    @include generate_size(120px, 120px);
  }
}
